﻿@import "../../bower_components/bootstrap/less/variables";

/* bootstrap */
.panel-heading h4 {
    margin: 0;
}

.glyphicon.spin {
    animation: spin 2s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

/* forms */
label.optional:after {
    content: " (optional)";
    color: lightgray;
    font-size: smaller;
}

/* labels */
.label-override(@color) {
    border-color: @color;
    color: @color;
}

.label {
    background-color: initial;
    border: 1px solid @label-default-bg;
    color: @label-default-bg
}

.label-default {
    .label-override(@label-default-bg)
}

.label-primary {
    .label-override(@label-primary-bg)
}

.label-success {
    .label-override(@label-success-bg)
}

.label-info {
    .label-override(@label-info-bg)
}

.label-warning {
    .label-override(@label-warning-bg)
}

.label-danger {
    .label-override(@label-danger-bg)
}

/* tables */
.panel > table > thead th {
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: normal;
}

.panel-info > table > thead {
    color: @panel-info-text;
    background-color: mix(@panel-info-heading-bg, #ffffff, 70%);

    th {
        border-color: @panel-info-border;
    }
}


/* dot net validation */
.has-error {
    input, select, textarea {
        background-color: #ffeeee;
    }
}

.help-block {
    &:empty {
        display: none;
    }

    &.text-danger {
        color: #a94442;
    }
}


.alert-sm {
    padding: 10px 15px;
}

@import "typeahead.less";
