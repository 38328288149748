﻿body {
    background: #F6F6F6;
    min-width: 291px;
    padding: 40px 0 40px;
}

/* header */
#header {
    text-align: center;
    a {
        text-decoration: none;
    }
}

#auth {
    margin-top: 20px;
    margin-bottom: 10px;

    * {
        display: inline-block
    }
}
