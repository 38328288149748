/* General Typeahead styling, from http://jsfiddle.net/ragulka/Dy9au/1/ */
.twitter-typeahead {
    width: 100%;
    position: relative;
    vertical-align: top;
}

.tt-input,
.tt-hint {
    margin: 0;
    width: 100%;
    vertical-align: middle;
    background-color: #ffffff;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
}

.tt-hint {
    color: #999999;
    z-index: 1;
    border: 1px solid transparent;
}

.tt-input {
    color: #555555;
    z-index: 2;
}

.input-sm,
.input-sm {
    &.tt-input,
    &.tt-hint {
        border-radius: 3px;
    }
}

.input-lg,
.input-lg {
    &.tt-input,
    &.tt-hint {
        border-radius: 6px;
    }
}

.input-group {
    .twitter-typeahead {
        &:first-child {
            .tt-input,
            .tt-hint {
                border-radius: 4px 0 0 4px !important;
            }
        }

        &:not(:first-child) {
            .tt-input,
            .tt-hint {
                border-left-width: 0;
            }
        }

        &:last-child {
            .tt-input, .tt-hint {
                border-radius: 0 4px 4px 0 !important;
            }
        }
    }

    &.input-group-sm .twitter-typeahead {
        &:first-child {
            .tt-input,
            .tt-hint {
                border-radius: 3px 0 0 3px !important;
            }
        }

        &:last-child {
            .tt-input,
            .tt-hint {
                border-radius: 0 3px 3px 0 !important;
            }
        }
    }

    &.input-group-lg .twitter-typeahead {
        &:first-child {
            .tt-input,
            .tt-hint {
                border-radius: 6px 0 0 6px !important;
            }
        }

        &:last-child {
            .tt-input,
            .tt-hint {
                border-radius: 0 6px 6px 0 !important;
            }
        }
    }
}

.input-sm.tt-input,
.hint-sm.tt-hint,
.input-group.input-group-sm .tt-input,
.input-group.input-group-sm .tt-hint {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-lg.tt-input,
.hint-lg.tt-hint,
.input-group.input-group-lg .tt-input,
.input-group.input-group-lg .tt-hint {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
}

.tt-menu {
    width: 100%;
    min-width: 160px;
    margin-top: 2px;
    padding: 5px 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.tt-suggestion {
    display: block;
    padding: 5px 20px;
    border-bottom: 1px solid #EEE;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
    }

    &.tt-cursor {
        background-color: #f5f5f5;
    }
}

.tt-message {
    padding: 0 10px;
    display: inline-block;
}
